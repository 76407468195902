import React, { useEffect, useRef, useState } from "react";
import { FiLogOut } from "react-icons/fi"; // Assurez-vous que l'import est correct

import "./TopBar.scss";
import { Badge } from "primereact/badge";
import UserAvatar from "../UserAvatar/UserAvatar";

import logo from "../../assets/images/logo.webp";
import { Button } from "primereact/button";
import { SlMenu } from "react-icons/sl";
import { Sidebar } from "primereact/sidebar";
import SideBar from "../SideBar/SideBar";
import { useAuth } from "../../services/AuthContext";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import { useHelpExerciceContext } from "../../services/HelpExerciceContext";
import { NOTIFICATIONS_ADMIN, NOTIFICATIONS_USER } from "../../settings/constant";
import { AiOutlineLogout } from "react-icons/ai";

interface Link {
  url: string;
  img: string;
  label: string;
}

interface TopBarProps {
  links?: Link[];
  text?: string;
  level: "top" | "bottom";
  isMobile: boolean;
  isLoginForm?: boolean;
  isAdmin?: boolean;
  part: string;
  isTopBar?: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ text, level, isMobile, isLoginForm, links, isAdmin, part, isTopBar }) => {
  const [visible, setVisible] = useState(false);
  const { user, signOut, signOutAdmin, userProfil, admin } = useAuth();
  const { getNotifications, nbNotification, getSubscribeById, saveSubscriptionExpire } = useHelpExerciceContext();
  const toast = useRef<Toast>(null);

  const accept = async () => {
    if (part === "user") {
      signOut()
    } else {
      signOutAdmin()
    }
  }

  const confirm1 = (event: any) => {
    confirmPopup({
        target: event.currentTarget,
        message: 'Êtes-vous sûr de vouloir vous déconnectez ?',
        icon: 'pi pi-exclamation-triangle',
        acceptClassName: 'p-button-danger',
        //defaultFocus: 'accept',
        accept
    });
};

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        type: part,
        userId: part === "user" ? user?.id:""
      }
      await getNotifications(data);
      
      if (userProfil) {
        await getSubscribeById(userProfil.id);
        
        await saveSubscriptionExpire(userProfil.id)
      }
    }

    fetchData();

  }, [nbNotification, userProfil, user]);

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Sidebar className="p-0" visible={visible} onHide={() => setVisible(false)}>
        <SideBar links={links ?? []} isMobile={false} part={part} />
      </Sidebar>
      {level === "top" ? (
        <div className="TopBar Dflex items-center space-between Prl">
          <Button
            style={{
              display: isMobile ? "block" : "none",
              padding: 0,
              background: "none",
              border: "none",
            }}
            onClick={() => setVisible(true)}
          >
            <SlMenu style={{ color: "white" }} className="Header-bugerMenu" />
          </Button>
          <p className="TopBar-text" style={{ display: isMobile ? "none" : "block" }}>
            {text}
          </p>
          {!isLoginForm ? 
            <button className="TopBar-logout Dflex items-center justify-center HFont" type="button"
            onClick={confirm1}>
            Se déconnecter
            <AiOutlineLogout />
          </button>:""}
          <ConfirmPopup />
          {isTopBar ? 
            <button className="TopBar-logout Dflex items-center justify-center HFont" type="button"
            onClick={part === "user" ? signOut:signOutAdmin}>
            Se déconnecter
            <FiLogOut />
          </button>:""
          }
        </div>
      ) : (
        <div className="TopBar2 Dflex items-center space-between Prl">
          <a className="TopBar2-logo Dflex items-center" href="/">
            <img src={logo} alt="Logo" />
          </a>
          {!isLoginForm ? <div className="TopBar2-right">
            {/* {!isAdmin ? <button
              className="TopBar2-learn TopBar2-btn Dflex items-center HFont"
              type="button"
              style={{ display: isMobile ? "none" : "flex" }}
            >
              Mes formations
              <Badge value="0" style={{ background: "#f97315", borderColor: "#f97315" }} />
            </button>:""} */}
            <button
              className="TopBar2-btn"
              type="button"
              title="Notifications"
              onClick={() => window.location.href = part === "admin" ? NOTIFICATIONS_ADMIN : NOTIFICATIONS_USER}
            >
              <i className="pi pi-bell p-overlay-badge" style={{ fontSize: "1.3rem" }}>
                <Badge value={nbNotification} style={{ background: "#f97315", borderColor: "#f97315" }} />
              </i>
            </button>
            <UserAvatar label={ part === "user" ? user ? user.fullname:"Utilisateur": `${admin && admin.fullname}`} isMobile={isMobile} />
          </div>:""}
        </div>
      )}
    </>
  );
};

export default TopBar;
