import React, { ReactNode } from "react";
import { useAuth } from "./services/AuthContext";
import { Navigate } from "react-router-dom";
import { DASHBOARD_LOGIN } from "./settings/constant";

const PrivateRouteAdmin: React.FC<{ children: ReactNode }> = ({ children }) => {
  const tokenAdmin = localStorage.getItem("accessTokenAdmin");
  if (!tokenAdmin) {
    return <Navigate to={DASHBOARD_LOGIN} replace />
  }
  return <>{children}</>;
};

export default PrivateRouteAdmin;
