import React, { ReactNode, useEffect, useState } from "react";

import "./Layout.scss";
import { BreadCrumb } from "primereact/breadcrumb";
import { MenuItem } from "primereact/menuitem";
import { Card } from "primereact/card";
import SideBar from "../components/SideBar/SideBar";
import TopBar from "../components/TopBar/TopBar";
import {
  CORRECTORS,
  COURSE_PARTICULAR_ADMIN,
  DASHBOARD_ADMIN_HOME,
  DASHBOARD_USER_HOME,
  HELP_EXERCICE_ADMIN,
  MESSAGES_ADMIN,
  SERVICES_PAGE_ADMIN,
  SUBSCRIBE,
  SUBSCRIPTION,
  TASKS,
  TASKS_ADMIN,
  USERADMIN,
} from "../settings/constant";
import { useLocation, useNavigate } from "react-router-dom";

import help_exercie from "../assets/images/help_exer_ico.webp";
import epreuve_corriger from "../assets/images/epre_corr_icon.webp";
import exman_concour from "../assets/images/exam_icon.webp";
import assistance_memoire from "../assets/images/ass_mem_icon.webp";
import stage_job from "../assets/images/jobStage.webp";
import cour_particul from "../assets/images/indual_course_icon.webp";
import solidrite from "../assets/images/solidarite_icon.webp";
import e_learning from "../assets/images/e-lean_icon.webp";
import service_icon from "../assets/images/icons8-services.svg";
import subscription_icon from "../assets/images/icons8-subscription-50.png";
import correctors from "../assets/images/icons8-group-64.png";
import homeImg from "../assets/images/home.svg";
import messageIcon from "../assets/images/mail-5886.svg";
import userIcon from "../assets/images/user-256.svg";
import task from "../assets/images/list-6235.svg";
import subscribe from "../assets/images/subscribes.svg";
import { useAuth } from "../services/AuthContext";

const LayoutAdmin: React.FC<{
  children: ReactNode;
  bg?: boolean;
  title?: string;
  title2?: string;
  title3?: string;
  isLoginForm?: boolean;
  isTopBar?: boolean;
}> = ({ children, bg, title, isLoginForm, isTopBar }) => {
  const { admin } = useAuth();
  const location = useLocation();
  const data = location?.state;

  const items = [{ label: "Tableau de bord" }, { label: title }];
  const home: MenuItem = {
    icon: `${data ? "pi pi-arrow-left" : "pi pi-home"}`,
    url: `${data ? data.previousUrl : DASHBOARD_USER_HOME}`,
  };

  const [isMobile, setIsMobile] = useState(false);

  const links: any = [
    { img: homeImg, label: "Tableau de bord", url: DASHBOARD_ADMIN_HOME },
    { img: messageIcon, label: "Messages", url: MESSAGES_ADMIN },
    ...(admin && admin.userRole === "AC" ? [{ img: subscribe, label: "Abonnées", url: SUBSCRIBE }]:[]),
    { img: task, label: "Tâches", url: TASKS_ADMIN },
    //{ img: service_icon, label: "Services", url: SERVICES_PAGE_ADMIN },
    ...(admin && admin.userRole === "AC" ? [{ img: subscription_icon, label: "Abonnements", url: SUBSCRIPTION }]:[]),
    { img: correctors, label: "Enseigants", url: CORRECTORS },
    ...(admin && admin.userRole === "AC" ? [{ img: userIcon, label: "Utilisateurs", url: USERADMIN }]:[]),
    { img: help_exercie, label: "Aide aux exercices", url: HELP_EXERCICE_ADMIN },
    { img: cour_particul, label: "Cours Particuliers", url: COURSE_PARTICULAR_ADMIN },
    /* { img: epreuve_corriger, label: "Recueils d'Épreuves et Corrigées", url: "" },
    { img: exman_concour, label: "Examens et Concours", url: "" },
    { img: assistance_memoire, label: "Assistance Mémoire", url: "" },
    { img: stage_job, label: "Aides aux Stages et aux Emplois", url: "" },
    { img: solidrite, label: "Solidarité", url: "" },
    { img: e_learning, label: "Formation en ligne", url: "" }, */
  ];

  useEffect(() => {
    //alert(document.referrer);
    const handleResize = () => {
      console.log(window.innerWidth < 1024);
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Vérifie initialement la taille de l'écran
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="DashboardUserAdmin">
     {!isLoginForm ?  
      <TopBar text="Bienvenue !" 
        level="top" 
        part="admin" 
        isMobile={isMobile} 
        isLoginForm={isLoginForm} 
        links={links} />
        :""
        }
      
      {isTopBar ? 
        <TopBar text="Mon espace" 
        level="top" 
        part="admin" 
        isMobile={isMobile} 
        isLoginForm={isLoginForm} 
        links={links}
        isTopBar={isTopBar} />
      :""}
      <TopBar level="bottom" part="admin" isMobile={isMobile} isLoginForm={isLoginForm} links={links} isAdmin={true} />

      <div className="DashboardUserAdmin-body Dflex">
        {isLoginForm ? (
          children
        ) : (
          <>
            <SideBar links={links} isMobile={isMobile} part="admin" />
            <div className="DashboardUserAdmin-bodyMain">
              <BreadCrumb className="w-full mb-3" model={items} home={home} />
              {bg ? (
                <Card className="DashboardUserAdmin-container">
                  {children ? children : <p>Cette page n'est pas encore disponible</p>}
                </Card>
              ) : (
                children
              )}
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default LayoutAdmin;
