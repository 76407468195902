import axios from "axios";
import { API_URL } from "../settings/constant";

const axiosInstanceAdmin = axios.create({
  baseURL: API_URL, // Mettez votre URL de backend
});

axiosInstanceAdmin.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessTokenAdmin");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstanceAdmin;