import React, { useEffect, useState } from "react";
import { SlMenu } from "react-icons/sl";

import { CONTACT, DASHBOARD_USER_HOME, HOME, LOGIN, PROFIL_USER, SERVICES } from "../../settings/constant";
import { Sidebar } from "primereact/sidebar";
import logo from "../../assets/images/logo.webp";
import {FloatingWhatsApp} from "react-floating-whatsapp"

import "./Header.scss";
import { Button } from "primereact/button";
import { useAuth } from "../../services/AuthContext";

import { BiSolidUser } from "react-icons/bi";

const Header: React.FC = () => {
  const { user } = useAuth();
  const [visible, setVisible] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const linkSite = [
    { url: HOME, label: "Accueil" },
    { url: SERVICES, label: "Nos Services" },
    { url: CONTACT, label: "Contactez-Nous" },
    /* { url: HEAD_OFFICE, label: "Notre Siège" }, */
  ];

  const url = window.location.href
  useEffect(() => {
    const allLi = document.querySelectorAll(".Header-li") as NodeListOf<HTMLElement>;
    //const pathname = location.pathname;
    Array.from(allLi).forEach((li: HTMLElement) => {
      const childSpan = li.querySelector("a");
      if (childSpan && url === childSpan.href) {
          li.classList.add('Header-active');
      } else {
        li.classList.remove('Header-active');
      }
    });

    const handleResize = () => {
      console.log(window.innerWidth < 1024);
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Vérifie initialement la taille de l'écran
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  },[url]);

  return (
    <header className="Header">
      <FloatingWhatsApp accountName="E-SMART" 
        phoneNumber="22955730303" 
        statusMessage="Assistance E-smart actif"
        chatMessage="Bienvenue chez E-smart ! Comment pouvons-nous vous renseigner ?" />
      <Sidebar className="pl-3 pr-3" visible={visible} onHide={() => setVisible(false)}>
        <ul className="Header-ul">
          {linkSite.map((list, index) => (
            <li className="Header-li" key={index.toString()}>
              <a className="Header-a" href={list.url}>
                {list.label}
              </a>
            </li>
          ))}
        </ul>
        <div className="Header-right mt-4">
          {user ?  
              <a type="button" className="Header-btnAccount flex align-items-center gap-2" href={DASHBOARD_USER_HOME} target="blank">
                <BiSolidUser />
                Mon compte 
              </a>
            :
              <a className="Header-btnConnect" href={LOGIN}>
                Se connecter
              </a>
          }
        </div>
      </Sidebar>
      <div className="Header-topBar Container Dflex items-center space-between">
        <a className="Header-logo" href={HOME}>
          <img className="Header-logoImg" src={logo} alt="" />
        </a>
        <ul
          className="Header-ul"
          style={{
            display: isMobile ? "none" : "flex",
          }}
        >
          {linkSite.map((list, index) => (
            <li className="Header-li" key={index.toString()}>
              <a className="Header-a" href={list.url}>
                {list.label}
              </a>
            </li>
          ))}
        </ul>
        <div
          className="Header-right gap-3"
          style={{
            display: isMobile ? "none" : "flex",
          }}
        >
          {user ?  
            <a type="button" className="Header-btnAccount flex align-items-center gap-2" href={DASHBOARD_USER_HOME} target="blank">
              <BiSolidUser />
              Mon tabeau de bord
            </a>
          :
            <a className="Header-btnConnect" href={LOGIN}>
              Se connecter
            </a>
          }
         
        </div>
        <Button
          style={{
            display: isMobile ? "block" : "none",
            padding: 0,
            background: "none",
            border: "none",
          }}
          onClick={() => setVisible(true)}
          >
          <SlMenu className="Header-bugerMenu" />
        </Button>
      </div>
    </header>
  );
};

export default Header;
