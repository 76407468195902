import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { FiPhone } from "react-icons/fi";
import { FaWhatsapp, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { IoLocationOutline } from "react-icons/io5";
import { TfiYoutube } from "react-icons/tfi";
import { BsTwitterX } from "react-icons/bs";

import "leaflet/dist/leaflet.css";
import "./Footer.scss";
import { CONTACT, PRIVACY_POLICY, SERVICES, TERMS_AND_CONDITIONS } from "../../settings/constant";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const allServices = [
    { title: "Aide aux exercices", description: "" },
    { title: "Recueils d'Épreuves et Corrigées", description: "" },
    { title: "Examens et Concours", description: "" },
    { title: "Assistance Mémoire", description: "" },
    { title: "Aides aux Stages et aux Emplois", description: "" },
    { title: "Cours Particuliers", description: "" },
    { title: "Solidarité", description: "" },
    { title: "Conseil aux professionnels", description: "" },
    { title: "Formations", description: "" },
  ];

  return (
    <footer className="Footer">
      <h1 className="Footer-smart">E-SMART</h1>
      <div className="Container Footer-block">
        <div className="Footer-top Dflex space-between">
          <div className="Footer-col Dflex flex-wrap gap20">
            <MapContainer
              className="Footer-map"
              center={[6.3672176, 2.4018039]}
              zoom={13}
              scrollWheelZoom={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[6.3672176, 2.4018039]}>
                <Popup>Carrefour Vodjè</Popup>
              </Marker>
            </MapContainer>
            <div className="Footer-contactInfosBox">
              <p className="Footer-contactInfo">
                <FiPhone />
                <span>00229 55 73 03 03</span>
              </p>
              <p className="Footer-contactInfo">
                <FaWhatsapp />
                <span>00229 55 73 03 03</span>
              </p>
              <p className="Footer-contactInfo">
                <GoMail />
                <span><a href="mailto:smartedsarl124@gmail.com">smartedsarl124@gmail.com</a></span>
              </p>
              <p className="Footer-contactInfo">
                <IoLocationOutline />
                <span>Vodjè, Cotonou, Bénin</span>
              </p>
            </div>
          </div>
          <div className="Footer-col Footer-colLink">
            <h1 className="Footer-h1 HFont mb-3">Nos services</h1>
            <ul className="Footer-ul">
              {allServices.map((list, index) => (
                <li className="Footer-li" onClick={() => navigate(SERVICES, {state: index})}>
                  <a className="Footer-a">
                    {list.title}
                  </a>
                </li>
              ))}
              
            </ul>
          </div>
          <div className="Footer-col">
            {/* <a className="Footer-btnContact" href={CONTACT}>
              Contactez-nous
            </a> */}
            <div className="Socials">
              <h2 className="Socials-h1 HFont mb-4">Suivez nous!</h2>
              <ul className="Socials-ul Dflex">
                <li className="Socials-li">
                  <a className="Socials-a" href="/">
                    <FaFacebookF />
                  </a>
                </li>
                <li className="Socials-li">
                  <a className="Socials-a" href="/">
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="Socials-li">
                  <a className="Socials-a" href="/">
                    <BsTwitterX />
                  </a>
                </li>
                <li className="Socials-li">
                  <a className="Socials-a" href="/">
                    <TfiYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="Footer-bottom Dflex items-center space-between">
          <ul className="Footer-ul2 Dflex items-center flex-wrap">
            <li className="Footer-li2">
              <a className="Footer-a2" href={PRIVACY_POLICY}>
                Politique de confidentialité
              </a>
            </li>
            <li className="Footer-li2">
              <a className="Footer-a2" href={TERMS_AND_CONDITIONS}>
                Condition d’utilisation
              </a>
            </li>
          </ul>
          <p className="Footer-copyright">
            @ {new Date().getFullYear()} E-SMART. Tous droits réservés
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
