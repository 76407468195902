import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import {
  ACTIVATE_ACCOUNT,
  ADD_CORRECTORS,
  CONFIRM_EMAIL,
  CONTACT,
  CORRECTORS,
  COURSE_PARTICULAR,
  COURSE_PARTICULAR_ADMIN,
  DASHBOARD_ADMIN_HOME,
  DASHBOARD_LOGIN,
  DASHBOARD_USER_HOME,
  DETAILS_EXERCICE_ADMIN,
  HEAD_OFFICE,
  HELP_EXERCICE,
  HELP_EXERCICE_ADMIN,
  HOME,
  LIST_EXERCICE,
  LOGIN,
  MESSAGES_ADMIN,
  MESSAGES_TO_CORRECTORS,
  MESSAGES_TO_STUDENT,
  NOTIFICATIONS_ADMIN,
  NOTIFICATIONS_USER,
  POSTULE_PAGE,
  PRIVACY_POLICY,
  PROFIL_USER,
  RESET_PWD,
  RESET_PWD_ADMIN,
  SERVICES,
  SERVICES_PAGE_ADMIN,
  SIGN_IN,
  SPACE_TEACHER,
  STAFF_DASHBOARD,
  SUBMIT_EXERCICE,
  SUBSCRIBE,
  SUBSCRIPTION,
  TASKS,
  TASKS_ADMIN,
  TERMS_AND_CONDITIONS,
  USERADMIN,
  VERIFY_EMAIL,
  VERIFY_EMAIL_ADMIN,
  VERIFY_MY_SUBSCRIPTION,
} from "./settings/constant";
import Layout from "./layouts/Layout";
import Loading from "./components/Loading/Loading";
import LayoutUser from "./layouts/LayoutUser";
import LayoutAdmin from "./layouts/LayoutAdmin";
import AddCorrector from "./pages/bo/admin/Correctors/AddCorrector";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import PrivateRouteAdminStaff from "./PrivateRouteAdminStaff";

/* site components */
const HomePage = lazy(() => import("./pages/site/Home/index"));
const Resgistration = lazy(() => import("./pages/site/authentification/Registration"));
const Login = lazy(() => import("./pages/site/authentification/Login"));
const ConfirmEmail = lazy(() => import("./pages/site/authentification/ConfirmEmail"));
const VerifyEmail = lazy(() => import("./pages/site/authentification/VerifyEmail"));
const ResetPassword = lazy(() => import("./pages/site/authentification/ResetPassword"));
const ActiveAccount = lazy(() => import("./pages/site/authentification/ActiveAccount"));
const Services = lazy(() => import("./pages/site/Services/Services"));
const HeadOffice = lazy(() => import("./pages/site/HeadOffice/HeadOffice"));
const Contact = lazy(() => import("./pages/site/Contact/Contact"));
const PostulatePage = lazy(() => import("./pages/site/Postulate/Postulate"));
const CourParticular = lazy(() => import("./pages/bo/users/CourseParticular/CourseParticular"));

/* dashboard user */
const DashboardHome = lazy(() => import("./pages/bo/users/Home/Home"));
const ProfilUser = lazy(() => import("./pages/bo/users/Profil/Profil"));
const HelpExercice = lazy(() => import("./pages/bo/users/HelpExercice/HelpExercice"));
const SubmitExercice = lazy(
  () => import("./pages/bo/users/HelpExercice/SubmitExercice/SubmitExercice"),
);
const ListExercice = lazy(
  () => import("./pages/bo/users/HelpExercice/ListExercices/ListExercices"),
);
const SubscriptionHelpExercice = lazy(
  () => import("./pages/bo/users/HelpExercice/Subscription/Subscription"),
);
const  NotificationsUser = lazy(() => import("./pages/bo/users/Notifications/Notifications"));

/* dashboard admin */
const ResetPwdAdmin = lazy(() => import("./pages/bo/admin/Auth/ResetPwd"));
const VerifyEmailAdmin = lazy(() => import("./pages/bo/admin/Auth/VerifyEmail")); 
const DashboardAdmin = lazy(() => import("./pages/bo/admin/Home/Home"));
const HelpExerciceAdmin = lazy(() => import("./pages/bo/admin/HelpExercice/ListExercice"));
const DetailsExercice = lazy(() => import("./pages/bo/admin/HelpExercice/DetailsExercice"));
const DashboardLogin = lazy(() => import("./pages/bo/admin/Auth/Login"));
const Correctors = lazy(() => import("./pages/bo/admin/Correctors/Correctors"));
const AddCorrectors = lazy(() => import("./pages/bo/admin/Correctors/AddCorrector"));
const ServicesAdmin = lazy(() => import("./pages/bo/admin/services/Services"));
const Subscription = lazy(() => import("./pages/bo/admin/subscription/Subscription"));
const UserAdmin = lazy(() => import("./pages/bo/admin/UserAdmin/UserAdmin"));
const Tasks = lazy(() => import("./pages/bo/admin/Tasks/Tasks"));
const MessageAdmin = lazy(() => import("./pages/bo/admin/Messages/Messages"));
const MessageToCorrectors = lazy(() => import("./pages/bo/admin/Messages/MessagesToCorrectors"));
const MessagesToStudent = lazy(() => import("./pages/bo/admin/Messages/MessagesToStudent"));
const SpaceCorrector = lazy(() => import("./pages/bo/admin/spaceCorrector/SpaceCorrector"));
const NotificationsAdmin = lazy(() => import("./pages/bo/admin/Notifications/Notifications"));
const CourParticularAdmin = lazy(() => import("./pages/bo/admin/CourseParticular/CourseParticular"));
const TaskAdmin = lazy(() => import("./pages/bo/admin/tasksExercice/Tasks"));
const Subscribe = lazy(() => import("./pages/bo/admin/subscribe/Subscribe"));
const TermsAndConditions = lazy(() => import("./pages/site/TermsAndConditions/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/site/PrivacyPolicy/PrivacyPolicy"));

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* auth routing */}
        <Route
          path={SIGN_IN}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <Resgistration />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={ACTIVATE_ACCOUNT}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <ActiveAccount />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={LOGIN}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <Login />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path={CONFIRM_EMAIL}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <ConfirmEmail />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path={VERIFY_EMAIL}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <VerifyEmail />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={RESET_PWD}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <ResetPassword />
              </Suspense>
            </Layout>
          }
        />

        {/* site routing */}
        <Route
          path={HOME}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <HomePage />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path={SERVICES}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <Services />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path={HEAD_OFFICE}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <HeadOffice />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path={SERVICES}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <Services />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={POSTULE_PAGE}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <PostulatePage />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path={CONTACT}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <Contact />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path={TERMS_AND_CONDITIONS}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <TermsAndConditions />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={PRIVACY_POLICY}
          element={
            <Layout>
              <Suspense fallback={<Loading />}>
                <PrivacyPolicy />
              </Suspense>
            </Layout>
          }
        />

        {/* dashboad user */}

        <Route
          path={DASHBOARD_USER_HOME}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Tableau de bord">
                <Suspense fallback={<Loading />}>
                  <DashboardHome />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />

        <Route
          path={COURSE_PARTICULAR}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Cours particulier">
                <Suspense fallback={<Loading />}>
                  <CourParticular />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />

        <Route
          path={PROFIL_USER}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Mon Profile">
                <Suspense fallback={<Loading />}>
                  <ProfilUser />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />

        <Route
          path={HELP_EXERCICE}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Aide aux Exercices">
                <Suspense fallback={<Loading />}>
                  <HelpExercice />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />
        <Route
          path={SUBMIT_EXERCICE}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Soumettre un exercice">
                <Suspense fallback={<Loading />}>
                  <SubmitExercice />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />
        <Route
          path={LIST_EXERCICE}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Liste des exercices">
                <Suspense fallback={<Loading />}>
                  <ListExercice />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />
        <Route
          path={VERIFY_MY_SUBSCRIPTION}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Abonnements">
                <Suspense fallback={<Loading />}>
                  <SubscriptionHelpExercice />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />
        <Route
          path={NOTIFICATIONS_USER}
          element={
            <PrivateRoute>
              <LayoutUser pageName="Notifications">
                <Suspense fallback={<Loading />}>
                  <NotificationsUser />
                </Suspense>
              </LayoutUser>
            </PrivateRoute>
          }
        />

        {/* dashboad admin */}

        <Route
          path={DASHBOARD_ADMIN_HOME}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin bg={false} isLoginForm={false}>
                <Suspense fallback={<Loading />}>
                  <DashboardAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={DASHBOARD_LOGIN}
          element={
            <LayoutAdmin bg={false} isLoginForm={true}>
              <Suspense fallback={<Loading />}>
                <DashboardLogin />
              </Suspense>
            </LayoutAdmin>
          }
        />

        <Route
          path={RESET_PWD_ADMIN}
          element={
            <LayoutAdmin bg={false} isLoginForm={true}>
              <Suspense fallback={<Loading />}>
                <ResetPwdAdmin />
              </Suspense>
            </LayoutAdmin>
          }
        />

        <Route
          path={VERIFY_EMAIL_ADMIN}
          element={
            <LayoutAdmin bg={false} isLoginForm={true}>
              <Suspense fallback={<Loading />}>
                <VerifyEmailAdmin />
              </Suspense>
            </LayoutAdmin>
          }
        />

        <Route
          path={HELP_EXERCICE_ADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Aide aux exercice"
                title2="Liste des exercice"
              >
                <Suspense fallback={<Loading />}>
                  <HelpExerciceAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />
        <Route
          path={DETAILS_EXERCICE_ADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Aide aux exercice > Détails"
                title2="Liste des exercice"
              >
                <Suspense fallback={<Loading />}>
                  <DetailsExercice />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />
        <Route
          path={CORRECTORS}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Enseignants"
                title2="Liste des enseignants"
              >
                <Suspense fallback={<Loading />}>
                  <Correctors />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />
        <Route
          path={ADD_CORRECTORS}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Enseignants"
                title2="Nouveau"
              >
                <Suspense fallback={<Loading />}>
                  <AddCorrectors />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={SERVICES_PAGE_ADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Services"
              >
                <Suspense fallback={<Loading />}>
                  <ServicesAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={TASKS_ADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Tâches"
              >
                <Suspense fallback={<Loading />}>
                  <TaskAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={SUBSCRIPTION}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Abonnements"
              >
                <Suspense fallback={<Loading />}>
                  <Subscription />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={USERADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Utilisateurs"
              >
                <Suspense fallback={<Loading />}>
                  <UserAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={TASKS}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Tâches"
              >
                <Suspense fallback={<Loading />}>
                  <Tasks />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={SUBSCRIBE}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Abonnées"
              >
                <Suspense fallback={<Loading />}>
                  <Subscribe />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={MESSAGES_ADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Message"
              >
                <Suspense fallback={<Loading />}>
                  <MessageAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={MESSAGES_TO_CORRECTORS}
          element={
           <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Message aux enseignants"
              >
                <Suspense fallback={<Loading />}>
                  <MessageToCorrectors />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={MESSAGES_TO_STUDENT}
          element={
           <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Message aux apprenants"
              >
                <Suspense fallback={<Loading />}>
                  <MessagesToStudent />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={NOTIFICATIONS_ADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin
                isLoginForm={false}
                bg={true}
                title="Notifications"
              >
                <Suspense fallback={<Loading />}>
                  <NotificationsAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

        <Route
          path={STAFF_DASHBOARD}
          element={
            <PrivateRouteAdminStaff>
              <LayoutAdmin bg={false} isLoginForm={true} isTopBar={false}>
                <Suspense fallback={<Loading />}>
                  <SpaceCorrector />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdminStaff>
          }
        />

        <Route
          path={COURSE_PARTICULAR_ADMIN}
          element={
            <PrivateRouteAdmin>
              <LayoutAdmin 
                isLoginForm={false}
                bg={true}
                title="Cours Particuliers">
                <Suspense fallback={<Loading />}>
                  <CourParticularAdmin />
                </Suspense>
              </LayoutAdmin>
            </PrivateRouteAdmin>
          }
        />

      </Routes>
    </Router>
  );
};

export default AppRouter;
