import React, { useEffect } from "react";
import "./UserAvatar.scss";

interface UserAvatarProps {
  label: string;
  isMobile: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ label, isMobile }) => {
  useEffect(() => {}, [isMobile]);
  return (
    <div className="Avatar flex align-items-center gap-3 ml-5">
      <h5 className="Avatar-avatarName HFont" style={{ display: isMobile ? "none" : "block" }}>
        {label}
      </h5>
      <div className="Avatar-avatar">
        <h3 className="Avatar-letterName">{label ? label.charAt(0).toUpperCase():"U"}</h3>
      </div>
    </div>
  );
};

export default UserAvatar;
