import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { API_URL, CORRECTORS, COURSE_PARTICULAR_ADMIN, DASHBOARD_ADMIN_HOME, DASHBOARD_LOGIN, HELP_EXERCICE_ADMIN, LIST_EXERCICE, LOGIN, MESSAGES_ADMIN, PROFIL_USER, SERVICES, SERVICES_PAGE_ADMIN, STAFF_DASHBOARD, SUBSCRIBE, SUBSCRIPTION, TASKS, USERADMIN, VERIFY_MY_SUBSCRIPTION } from "../settings/constant";
import { useAuth } from "./AuthContext";
import axiosInstanceAdmin from "../config/axiosConfigAdmin";
import axiosInstanceUser from "../config/axiosConfig";

// Définition du type pour les données
type FormData = {
  userId: string | undefined;
  matter: string;
  concerns: string;
  files: File[]
};

interface VerifyExerciceResponse {
  status: number;
  exerciceFile: string[];
}

interface TaskInterval {
  status: number;
  tasksByUser?: [];
}

interface SubmitExerciceResponse {
  status: number;
  data: any;
}

// Définition du type pour le context
type HelpContextType = {
  formDataList: FormData[];
  exerciceByUser:  any[],
  exerciceById:  any,
  correctorById:  any,
  correctorByMatter:  any[],
  exercices:  any[],
  correctors: any[],
  services: any[],
  serviceById: any,
  subscription: any[],
  subscriptionById: any,
  usersAdmin: any[],
  tasks: any[];
  tasksById: any;
  tasksByUser: any[];
  allExamens: any[];
  allNotifications: any[];
  allCourseParticular: any[];
  allStatsAdmin: any;
  stats: any;
  nbNotification: number;
  subscribes: any[];
  subscribeById: any;
  submitExercice: (formData: any) => Promise<SubmitExerciceResponse>;
  updateFormData: (index: number, formData: FormData) => void;
  getExerciceByUser: () => Promise<void>;
  getExerciceById: (id: string) => Promise<void>;
  getExercices: () => Promise<void>;
  updateExercice: (id: string, formData: any) => Promise<SubmitExerciceResponse>;
  deleteExercice: (id: string) => Promise<number>;
  sendExerciceToCorrector: (formData: any) => Promise<number>;
  addCorrector: (formData: any) => Promise<number>;
  getCorrectors: () => Promise<void>;
  getCorrectorById: (id: string) => Promise<void>;
  updateCorrector: (id: string, formData: any) => Promise<number>;
  deleteCorrector: (id: string) => Promise<number>;
  downloadSheetExcelCorrectors: () => Promise<number>;
  getCorrectorByMatter: (matter: string) => Promise<void>;
  contactMail: (formData: any) => Promise<number>;
  createService: (formData: any) => Promise<number>;
  getService: () => Promise<void>;
  getServiceById: (id: string) => Promise<void>;
  updateService: (id: string, formData: any) => Promise<number>;
  deleteService: (id: string) => Promise<number>;
  createSubscription: (formData: any) => Promise<number>;
  getSubscription: () => Promise<void>;
  getSubscriptionById: (id: string) => Promise<void>;
  updateSubscrption: (id: string, formData: any) => Promise<number>;
  deleteSubscription: (id: string) => Promise<number>;
  createUserAdmin: (formData: any) => Promise<number>;
  updateUserAdmin: (id: string, formData: any) => Promise<number>;
  getUserAdmin: () => Promise<void>;
  deleteUserAdmin: (id: string) => Promise<number>;
  launchRemindsExercise: (formData: any) => Promise<number>;
  verifyExerciceExisting: (filesExercice: string) => Promise<VerifyExerciceResponse>;
  handleDownloadImage: (imageName: string, idEx?: string) => Promise<void>;
  openImageInOnglet: (imageName: string) => Promise<void>;
  getExamens: () => Promise<void>;
  sendBroadcastMessage: (formData: any) => Promise<number>; 
  getTasksByUser: (id: string) => Promise<void>;
  sendResponseToUser: (formData: any) => Promise<number>;
  submitRecrutmentForm: (formData: any) => Promise<number>;
  sendFormCourParticular: (formData: any) => Promise<number>;
  getNotifications: (formData: any) => Promise<void>;
  updateNotifications: (formData: any) => Promise<void>;
  getSignUpCoursParticular: () => Promise<void>;
  deleteSignUpCoursParticular: (id: string) => Promise<number>;
  getStats?: () => Promise<void>;
  getStats2?: () => Promise<void>;
  approveTeacher: (id: string) => Promise<number>;
  sendResponseToExercice: (formData: any) => Promise<number>;
  acceptOrRejectExerciceByTeacher: (formData: any) => Promise<number>;
  getTasks: () => Promise<void>;
  sendLinkTeacherByEmail: (formData: any) => Promise<number>;
  getTaskTeacheByInterval: (formData: any) => Promise<TaskInterval>;
  createSubscribe: (formData: any) => Promise<number>;
  getSubscribeById: (id: string) => Promise<void>;
  saveSubscriptionExpire: (id: string) => Promise<number>;
  rejectedExercice: (formData: any) => Promise<number>;
  //getTasksById: (id: string) => Promise<void>;
};

// Création du context
const HelpContext = createContext<HelpContextType>({
  formDataList: [],
  exerciceByUser:  [],
  exerciceById:  null,
  correctorById:  null,
  correctorByMatter:  [],
  exercices:  [],
  correctors:  [],
  services: [],
  serviceById: null,
  subscription: [],
  subscriptionById: null,
  usersAdmin: [],
  tasks: [],
  tasksById: [],
  tasksByUser: [],
  allExamens: [],
  allNotifications: [],
  allCourseParticular: [],
  allStatsAdmin: null,
  nbNotification: 0,
  stats: null,
  subscribes: [],
  subscribeById: null,
  submitExercice: async () => {
    return {status: 500, data: null};
  },
  updateFormData: () => {},
  getExerciceByUser: async () => {},
  getExerciceById: async () => {},
  getExercices: async () => {},
  updateExercice: async () => { return {status: 500, data: null}; },
  deleteExercice: async () => { return 500; },
  sendExerciceToCorrector: async () => { return 500; },
  addCorrector: async () => { return 500; },
  getCorrectors: async () => {},
  getCorrectorById: async () => {},
  updateCorrector: async () => { return 500; },
  deleteCorrector: async () => { return 500; },
  downloadSheetExcelCorrectors: async () => { return 500; },
  getCorrectorByMatter: async () => {},
  contactMail: async () => { return 500; },
  createService: async () => { return 500; },
  getService: async () => {},
  getServiceById: async () => {},
  updateService: async () => { return 500; },
  deleteService: async () => { return 500; },
  createSubscription: async () => { return 500; },
  getSubscription: async () => {},
  getSubscriptionById: async () => {},
  updateSubscrption: async () => { return 500; },
  deleteSubscription: async () => { return 500; },
  createUserAdmin: async () => { return 500; },
  getUserAdmin: async () => {},
  updateUserAdmin: async () => { return 500; },
  deleteUserAdmin: async () => { return 500; },
  launchRemindsExercise: async () => { return 500; },
  verifyExerciceExisting: async () => {
    return { status: 500, exerciceFile: [] };
  },
  handleDownloadImage: async () => {},
  openImageInOnglet: async () => {},
  getExamens: async () => {},
  sendBroadcastMessage: async () => { return 500; },
  getTasksByUser: async () => {},
  sendResponseToUser: async () => { return 500; },
  submitRecrutmentForm: async () => { return 500; },
  sendFormCourParticular: async () => { return 500; },
  getNotifications: async () => {},
  updateNotifications: async () => {},
  getSignUpCoursParticular: async () => {},
  deleteSignUpCoursParticular: async () => { return 500; },
  getStats: async () => {},
  getStats2: async () => {},
  approveTeacher: async () => { return 500; },
  sendResponseToExercice: async () => { return 500; },
  acceptOrRejectExerciceByTeacher: async () => { return 500; },
  getTasks: async () => {},
  sendLinkTeacherByEmail: async () => { return 500; },
  getTaskTeacheByInterval: async () => { return { status: 500, tasksByUser: []}; },
  createSubscribe: async () => { return 500; },
  getSubscribeById: async () => {},
  saveSubscriptionExpire: async () => { return 500; },
  rejectedExercice: async () => { return 500; },
  /*getTasksById: async () => {},
  getTasksByUser: async () => {}, */
});

// Hook pour utiliser le context
export const useHelpExerciceContext = (): HelpContextType => useContext(HelpContext);

// Component fournisseur du context
export const HelpExerciceProvider = ({ children }: { children: ReactNode }) => {
  const [formDataList, setFormDataList] = useState<FormData[]>([]);
  const [exerciceByUser, setExerciceByUser] = useState<any[]> ([]);
  const [exerciceById, setExerciceById] = useState<any> (null);
  const [exercices, setExercices] = useState<any[]> ([]);
  const [correctorById, setCorrectorById] = useState<any> (null);
  const [correctorByMatter, setCorrectorByMatter] = useState<any[]> ([]);
  const [correctors, setCorrectors] = useState<any[]> ([]);
  const [services, setServices] = useState<any[]>([]);
  const [serviceById, setServiceById] = useState<any>(null);
  const [subscription, setSubscription] = useState<any[]>([]);
  const [usersAdmin, setUserAdmin] = useState<any[]>([]);
  const [subscriptionById, setSubscriptionById] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [tasksById, setTasksById] = useState<any>(null);
  const [tasksByUser, setTasksByUser] = useState<any[]>([]);
  const [allExamens, setAllExamens] = useState<any[]>([]);
  const [allNotifications, setNotifications] = useState<any[]>([]);
  const [allCourseParticular, setAllCourseParticular] = useState<any[]>([]);
  const [allStatsAdmin, setAllStatsAdmin] = useState<any>(null);
  const [stats, setStats] = useState<any>(null);
  const [nbNotification, setNbNotification] = useState<number>(0);
  const [teacherById, setTeacherById] = useState<any>(null);
  const [subscribes, setSubscribes] = useState<any[]>([]);
  const [subscribeById, setSubscribeById] = useState<any>(null);

  const { user, userProfil } = useAuth();

  const redirectLogin = (error: any, key = "accessTokenAdmin") => {
    const tokenAdmin = localStorage.getItem(key);
    if (error.response && error.response.status && error.response.status === 401) {
        if (error.response.statusText === "Unauthorized") {
            if (tokenAdmin) {
              if (key === "accessTokenAdmin") {
                localStorage.removeItem(key);
                window.location.href = DASHBOARD_LOGIN;
              } 
            } else if (key === "accessToken") {
              localStorage.removeItem(key);
              window.location.href = LOGIN;
            }
        }
    }
  }

  // Fonction pour enregistrer les données
  const submitExercice = async (formData: any): Promise<SubmitExerciceResponse> => {
    const formDataToSend = new FormData(); // Création de l'objet FormData

    // Ajout des données de formulaire
    formDataToSend.append('userId', formData.userId || '');
    formDataToSend.append('matter', formData.matter);
    formDataToSend.append('concerns', formData.concerns);
    formDataToSend.append('exerciceMentioned', formData.exerciceMentioned);
    formDataToSend.append('nbExerciceMentioned', formData.nbExerciceMentioned);

    // Ajout des fichiers
    formData.files.forEach((file: any) => {
      formDataToSend.append('files', file); // Utilisation d'un nom unique pour chaque fichier
    });

    console.log(formDataToSend)

    try {
      const response = await axiosInstanceUser.post(`${API_URL}submit-exercice`, formDataToSend);
      console.log(response);
      return {status: response.status, data: response.data};
    } catch (error: any) {
      redirectLogin(error, "accessToken");
      console.error("Erreur lors de l'enregistrement des données :", error);
      return {status: error.response?.status || 500, data: error?.response?.data};
    }
  };

  const sendExerciceToCorrector = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}send-exercice-to-corrector`, formData);
      console.log(response);
      return response.status;
    } catch (error: any) {
      redirectLogin(error);
      console.error("Erreur lors de l'enregistrement des données :", error);
      return error.response?.status || 500;
    }
  };

  // Fonction pour mettre à jour les données
  const updateFormData = (index: number, formData: FormData) => {
    const updatedFormDataList = [...formDataList];
    updatedFormDataList[index] = formData;
    setFormDataList(updatedFormDataList);
  };

  // Fonction pour récupérer la liste des données
  const getExerciceByUser = async (): Promise<void> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}get-exercices-by-user/${user?.id}`);
      console.log(response.data)

      if(response.status === 200) {
        setExerciceByUser(response.data);
      }
    } catch (error: any) {
      redirectLogin(error, "accessToken");
      console.error("Erreur lors de la récupération des données :", error);
      return error;
    }
  };

  const getExerciceById = async (id: string): Promise<void> => {
    try {
      const response = await axios.get(`${API_URL}get-exercices-by-id/${id}`);

      if(response.status === 200) {
        setExerciceById(response.data);
      }
    } catch (error: any) {
      console.error("Erreur lors de la récupération des données :", error);
      return error;
    }
  };

  const getExercices = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}get-exercices`);
      console.log(response.data)

      if (response.status === 200) {
        setExercices(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      console.error("Erreur lors de la récupération des données :", error);
      return error;
    }
  };

  const updateExercice = async (id: string, formData: any): Promise<SubmitExerciceResponse> => {
    const formDataToSend = new FormData(); // Création de l'objet FormData

    // Ajout des données de formulaire
    formDataToSend.append('userId', formData.userId || '');
    if (formData.matter && formData.matter !== "" && formData.matter !== null) {
      formDataToSend.append('matter', formData.matter);
    }
    if (formData.concerns && formData.concerns !== "" && formData.concerns !== null) {
      formDataToSend.append('concerns', formData.concerns);
    }

    if (formData.exerciceMentioned && formData.exerciceMentioned !== null && formData.exerciceMentioned !== "") {
      formDataToSend.append('exerciceMentioned', formData.exerciceMentioned);
    } 

    if (formData.nbExerciceMentioned && formData.nbExerciceMentioned !== null && formData.nbExerciceMentioned !== "") {
      formDataToSend.append('nbExerciceMentioned', formData.nbExerciceMentioned);
    } 

    // Ajout des fichiers
    if (formData.files && formData.files.length > 0) {
      formData.files.forEach((file: any) => {
        formDataToSend.append('files', file); // Utilisation d'un nom unique pour chaque fichier
      });
    }

    console.log(formDataToSend)
    try {

      const response = await axiosInstanceUser.put(`${API_URL}update-exercice/${id}`, formDataToSend);
      return {status: response.status, data: response.data};
    } catch (error: any) {
        redirectLogin(error, "accessToken");
        console.error("Erreur lors de l'ajout du correcteur :", error);
        return {status: error.response?.status || 500, data: error?.response?.data};
    }
  };

  const deleteExercice = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.delete(`${API_URL}delete-exercice/${id}`);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error, "accessToken");
      console.error("Erreur lors de la récupération des données :", error);
      return error.response?.status || 500;
    }
  };


  /**
   * 
   * Admin operation 
   */

  // opération sur les correcteurs
  const addCorrector = async (formData: any): Promise<number> => {
    try {
        const response = await axiosInstanceAdmin.post(`${API_URL}add-corrector`, formData);
        console.log(response);
        return response.status;
    } catch (error: any) {
        redirectLogin(error);
        console.error("Erreur lors de l'ajout du correcteur :", error);
        return error.response?.status || 500;
    }
  };

  const getCorrectors = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}get-correctors`);
      console.log(response)
      if (response.status === 200) {
        setCorrectors(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      console.error("Erreur lors de la récupération des données :", error);
      return error;
    }
  };

  const getCorrectorById = async (id: string): Promise<void> => {
    try {
      const response = await axios.get(`${API_URL}get-corrector/${id}`);
      if(response.status === 200) {
        setCorrectorById(response.data);
      }
    } catch (error) {
      redirectLogin(error);
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  const updateCorrector = async (id: string, formData: any): Promise<number> => {
    try {
        const response = await axiosInstanceAdmin.put(`${API_URL}update-corrector/${id}`, formData);
        return response.status;
    } catch (error: any) {
        redirectLogin(error);
        console.error("Erreur lors de l'ajout du correcteur :", error);
        return error.response?.status || 500;
    }
  };

  const deleteCorrector = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.delete(`${API_URL}delete-corrector/${id}`);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      console.error("Erreur lors de la récupération des données :", error);
      return error.response?.status || 500;
    }
  };

  const downloadSheetExcelCorrectors = async (): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}download-sheet-correctors`, {
        responseType: "blob", // Spécification du type de réponse blob
        headers: {
          "Content-Type": "application/json"
        }
      });
  
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
        // Créer un lien de téléchargement et le déclencher
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "transactions.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        // Libérer l'URL d'objet
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Erreur lors du téléchargement du fichier Excel");
      }
  
      return response.status;
    } catch (error: any) {
      redirectLogin(error);
      console.error("Erreur lors du téléchargement du fichier :", error);
      return error.response?.status || 500;
    }
  };

  const getCorrectorByMatter = async (matter: string): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}get-corrector-by-matter`, { matter: matter });
      console.log(response.data);
      if(response.status === 200) {
        setCorrectorByMatter(response.data)
      }
    } catch (error: any) {
      redirectLogin(error);
      return error;
    }
  }

  const contactMail = async (formData: any): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}contact-mail`, formData);
      return response?.status;
    } catch (error: any) {
      return error.response?.status || 500;
    }
  }

  // services
  const createService = async (formData: any): Promise<number> => {
    try {
      const formDataToSend = new FormData(); // Création de l'objet FormData
      formDataToSend.append('serviceName', formData.serviceName);
      formDataToSend.append('description', formData.description);

      if(formData.files) {
        formDataToSend.append('files', formData.files); 
      }

      const response = await axiosInstanceAdmin.post(`${API_URL}services/create`, formDataToSend);
      return response?.status;
    } catch (error: any) { 
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const getService = async (): Promise<void> => {
    try {
      const response = await axios.get(`${API_URL}services/get`);
      console.log(response.data)
      if(response?.status === 200) {
        setServices(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const getServiceById = async (id: string): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}services/get/${id}`);
      if(response?.status === 200) {
        setServiceById(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const updateService = async (id: string, formData: any): Promise<number> => {
    try {
      
      const formDataToSend = new FormData(); // Création de l'objet FormData
      if (formData.serviceName && formData.serviceName !== '' && formData.serviceName !== null) {
        formDataToSend.append('serviceName', formData.serviceName ?? '');
      }
      formDataToSend.append('description', formData.description);

      if(formData.files) {
        console.log(formData.files)
        formDataToSend.append('files', formData.files); 
      }
      console.log(formDataToSend)

      const response = await axiosInstanceAdmin.put(`${API_URL}services/update/${id}`, formDataToSend);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const deleteService = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.delete(`${API_URL}services/delete/${id}`);      
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };
  

  // subscription
  const createSubscription = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}subscription/create`, formData);
      console.log(response);
      
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const getSubscription = async (): Promise<void> => {
    try {
      const response = await axios.get(`${API_URL}subscription/get`);
      console.log(response)
      if(response?.status === 200) {
        setSubscription(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const getSubscriptionById = async (id: string): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}subscription/get/${id}`);
      if(response?.status === 200) {
        setSubscriptionById(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const updateSubscrption = async (id: string, formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.put(`${API_URL}subscription/update/${id}`, formData);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const deleteSubscription = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.delete(`${API_URL}subscription/delete/${id}`);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };
  
  // user admin
  const createUserAdmin = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}admin/create`, formData);
      console.log(response);
      
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const getUserAdmin = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}admin/get`);
      console.log(response.data)
      if(response?.status === 200) {
        setUserAdmin(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };


  const updateUserAdmin = async (id: string, formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.put(`${API_URL}admin/update/${id}`, formData);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const deleteUserAdmin = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.delete(`${API_URL}admin/delete/${id}`);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  // tasks
  const getTasks = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}tasks/get`);
      console.log(response)
      if (response?.status === 200) {
        setTasks(response.data);
      }
    } catch (error: any) {
      console.log(error);
      redirectLogin(error);
      return error.response?.status || 500;
    }
  }

  const getTasksById = async (id: string): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}tasks/get/${id}`);
      if (response?.status === 200) {
        setTasksById(response.data);
      }
    } catch (error: any) {
      console.log(error);
      redirectLogin(error);
      return error.response?.status || 500;
    }
  }

  const getTasksByUser = async (id: string): Promise<void> => {
    try {
      const response = await axios.get(`${API_URL}tasks/by-staff-corrector/${id}`);
      console.log(response);
      if (response?.status === 200) {
        setTasksByUser(response.data);
      }
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const launchRemindsExercise = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}tasks/remind-exercice`, formData);
      return response?.status;
    } catch (error: any) {
      console.log(error);
      redirectLogin(error);
      return error.response?.status || 500;
    }
  }

  const verifyExerciceExisting = async (fileNames: string): Promise<VerifyExerciceResponse> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}exercice/verify`, {
        exerciceFiles: fileNames
      });
  
      console.log(response);
      return { status: response.status, exerciceFile: response.data.fileResemble };
    } catch (error: any) {
      redirectLogin(error);
      console.error(error);
      return { status: error.response?.status || 500, exerciceFile: [] };
    }
  }

  // télécharger une image
  const handleDownloadImage = async (imageName: string, idEx?: string): Promise<void> => {
    try {
      const response = await axios.get(`${API_URL}download-image/${imageName}`,  {
        responseType: 'blob' // Indiquer que la réponse est un blob (fichier)
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(imageName);
      const ext = imageName.split(".").pop()?.toLowerCase();
      let link; 
      link = document.createElement('a');

      if (ext === "pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const fileUrl = URL.createObjectURL(blob);
       // window.open(fileUrl, '_blank'); 
        link.href = fileUrl;
        link.setAttribute('download', `exercice_smart_${idEx}_pdf.pdf`);
      } else if (ext === "docx") {
        const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
        const fileUrl = URL.createObjectURL(blob);
        link.href = fileUrl;
        link.setAttribute('download', `exercice_smart_${idEx}_document.docx`);
      } else {
        link.href = url;
        link.setAttribute('download', `exercice_smart_${idEx}_image.${ext}`); // Nom de fichier pour le téléchargement
        document.body.appendChild(link);
      }

      // Simuler un clic sur le lien pour déclencher le téléchargement
      link.click();
    } catch (error: any) {
      console.error('Erreur lors du téléchargement de l\'image :', error);
      return error.response?.status || 500;
    }
  };

  const openImageInOnglet = async (imageName: string): Promise<void> => {
    if (!imageName) {
      return;
    } else {
      const imageUrl = `${API_URL}${imageName}`; 
      window.open(imageUrl, '_blank');
    }
  };

  const getExamens = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}examens/get`);
      console.log(response.data)
      if (response.status === 200) {
        setAllExamens(response.data);
      }
    } catch (error: any) {
      redirectLogin(error);
      console.error("Erreur lors de la récupération des données :", error);
      return error.response?.status || 500;
    }
  };
  
  const sendBroadcastMessage = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}send-message/broadcast`, formData);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      console.error("Erreur lors de la récupération des données :", error);
      return error.response?.status || 500;  
    }
  }

  const sendResponseToUser = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}exercice/send-response-to-user`, formData);
      console.log(response)
      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

/* 
  const sendResponseToUser = async (formData: any): Promise<number> => {
    try {
      const formDataToSend = new FormData(); // Création de l'objet FormData
      formDataToSend.append('email', formData.email);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('idEx', formData.idEx);

      formData.files.forEach((file: any, index: number) => {
        formDataToSend.append('files', file); // Utilisation d'un nom unique pour chaque fichier
      });

      const response = await axiosInstanceAdmin.post(`${API_URL}exercice/send-response-to-user`, formDataToSend);
      console.group(response)
      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  } */

  const submitRecrutmentForm = async (formData: any): Promise<number> => {
    try {
      const res = await axios.post(`${API_URL}recrutment-form`, formData);
      console.log(res);
      return res?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const sendFormCourParticular = async (formData: any) => {
    try {
      const res = await axiosInstanceUser.post(`${API_URL}send-form-course-particular`, formData);
      console.log(res);
      return res.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const getNotifications = async (formData: any): Promise<void> => {
    try {
      const response = await axios.post(`${API_URL}get-notifications`, formData);
      if (response.status === 200) {
        const data  = response.data;
        setNotifications(data);
        
        let dataView = data.filter((item:any) => item.isView === false);
        setNbNotification(dataView.length)
        
      }
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const updateNotifications = async (formData: any ): Promise<void> => {
    try {
      const response = await axios.put(`${API_URL}update-notifications`, formData);
      console.log(response);
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const getSignUpCoursParticular = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}get/course-particular`);
      console.log(response);
      if (response.status === 200) {
        setAllCourseParticular(response.data);
      }
    } catch (error: any) {
      console.log(error);
      redirectLogin(error, "accessTokenAdmin");
      return error.response?.status || 500;
    }
  }

  const deleteSignUpCoursParticular = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.delete(`${API_URL}delete/signup-course-particular/${id}`);
      if (response.status === 200) {
        getSignUpCoursParticular();
      }
      return response?.status;
    } catch (error: any) {
      redirectLogin(error, "accessTokenAdmin");
      console.error("Erreur lors de la récupération des données :", error);
      return error.response?.status || 500;
    }
  };

  const getStats = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}get/stats-admin`);
      console.log(response);
      if (response.status === 200) {
        setAllStatsAdmin(response.data);
      }
    } catch (error: any) {
      console.log(error);
      redirectLogin(error, "accessTokenAdmin");
      return error.response?.status || 500;
    }
  }

  const getStats2 = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}get/stats2`);
      if (response.status === 200) {
        setStats(response.data);
      }
    } catch (error: any) {
      return error.response?.status || 500;
    }
  }

  const approveTeacher = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}teacher/approve/${id}`);
      console.log(response);
      return response.status;
    } catch (error: any) {
      console.log(error);
      redirectLogin(error, "accessTokenAdmin");
      return error.response?.status || 500;
    }
  }

  const sendResponseToExercice = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}exercice/send-response-to-exercice`, formData);
      console.log(response)
      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const acceptOrRejectExerciceByTeacher = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.put(`${API_URL}exercice/accept-reject-exercice-by-teacher`, formData);
      console.log(response)
      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const getTeacherById = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}get-teacher-by-id/${id}`);
      console.log(response.data)

      if(response.status === 200) {
        setTeacherById(response.data);
      }

      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  };

  const sendLinkTeacherByEmail = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.post(`${API_URL}teacher/send-link`, formData);
      return response?.status;
    } catch (error: any) {
      redirectLogin(error);
      return error.response?.status || 500;
    }
  };

  const getTaskTeacheByInterval = async (formData: any): Promise<TaskInterval> => {
    try {
      const response = await axios.post(`${API_URL}task/by-interval-and-download`, formData,{
        ...(formData.download ? {
          responseType: "blob", // Spécification du type de réponse blob
          headers: {
            "Content-Type": "application/json"
          }
        }:{})
      });
      console.log(response)
      let fullname = formData.fullname ? formData.fullname : `${correctorById.firstname}_${correctorById.lastname}`;
      if (response.status === 200) {
        if (formData.download) {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
          // Créer un lien de téléchargement et le déclencher
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `bilan_E-SMART_${fullname}_${formData.startDate}_to_${formData?.endDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          // Libérer l'URL d'objet
          window.URL.revokeObjectURL(url);

          return { status: response?.status};
        } else {
          setTasksByUser([])
          return { status: response?.status, tasksByUser: response?.data};
        }
      } 
      else if (response?.status === 204) {
        alert("Auncune donnée trouvée dans cet intervalle")
      }

      return { status: response?.status};
    } catch (error: any) {
      console.log(error);
      return { status: error.response?.status || 500, tasksByUser: []};
    }
  };

  const createSubscribe = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.post(`${API_URL}subscribe/create`, formData);
      console.log(response)
      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const getSubscribes = async (): Promise<void> => {
    try {
      const response = await axiosInstanceAdmin.get(`${API_URL}subscribe/get-all`);
      console.log(response)
      if (response.status === 200) {
        setSubscribes(response.data);
      }
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const getSubscribeById = async (id: string): Promise<void> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}subscribe/get-by-id/${id}`);
      console.log(response)
      if (response.status === 200) {
        setSubscribeById(response.data);
      }
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const saveSubscriptionExpire = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}subscribe/desactive-subscription/${id}`);
      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }

  const rejectedExercice = async (formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceAdmin.put(`${API_URL}exercice/reject`, formData);
      return response?.status;
    } catch (error: any) {
      console.log(error);
      return error.response?.status || 500;
    }
  }


  useEffect(() => {
    const url = window.location.pathname;
    const fetchData = async () => {

      await getStats2();

      if (url === DASHBOARD_ADMIN_HOME) {
        await getStats();
      }
      
      if (url === USERADMIN) {
        await getUserAdmin();
      }

     // if (url === CORRECTORS) {
        await getCorrectors();
      //}
      
      if (url === SUBSCRIPTION ||  url === VERIFY_MY_SUBSCRIPTION) {
        await getSubscription();
      }
      
      if (url === SERVICES_PAGE_ADMIN || url.includes(SERVICES)) {
        await getService();
      }
      
      if(url === TASKS) {
        console.log("tache")
        await getTasks();
      }
      
      if (url === MESSAGES_ADMIN || url === PROFIL_USER || url === SUBSCRIPTION) {
        await getExamens();
      }
      
      if (url === HELP_EXERCICE_ADMIN  || url === DASHBOARD_ADMIN_HOME) {
        await getExercices();
      }

      if (url === COURSE_PARTICULAR_ADMIN) {
        getSignUpCoursParticular();
      }
      
      // console.log(LIST_EXERCICE)
      if ( url === LIST_EXERCICE) {
        console.log(url)
        await getExerciceByUser();
      }

      if (url.includes("enseignant")) {
        const url = window.location.pathname;
        const id = url.split("/")[3];

        if (id) {
          const data = {
            type: "teacher",
              teacherId: id
          };

          await getCorrectorById(id);
          await getTasksByUser(id);
        }
      }

      if (url === SUBSCRIBE) {
        await getSubscribes();
      }
    };
    
    fetchData();
  }, []);

  return (
    <HelpContext.Provider
      value={{
        exerciceByUser,
        exerciceById,
        exercices,
        formDataList,
        correctors,
        correctorById,
        correctorByMatter,
        services,
        serviceById,
        subscription,
        subscriptionById,
        usersAdmin,
        tasks,
        tasksById,
        tasksByUser,
        allExamens,
        allNotifications,
        allCourseParticular,
        allStatsAdmin,
        nbNotification,
        subscribes,
        subscribeById,
        stats,
        submitExercice,
        updateFormData,
        getExerciceByUser,
        getExerciceById,
        getExercices,
        updateExercice,
        deleteExercice,
        sendExerciceToCorrector,
        addCorrector,
        getCorrectors,
        getCorrectorById,
        updateCorrector,
        deleteCorrector,
        downloadSheetExcelCorrectors,
        getCorrectorByMatter,
        contactMail,
        createService,
        getService,
        getServiceById,
        updateService,
        deleteService,
        createSubscription,
        getSubscription,
        getSubscriptionById,
        updateSubscrption,
        deleteSubscription,
        createUserAdmin,
        getUserAdmin,
        updateUserAdmin,
        deleteUserAdmin,
        launchRemindsExercise,
        verifyExerciceExisting,
        handleDownloadImage,
        openImageInOnglet,
        sendBroadcastMessage,
        getExamens,
        getTasksByUser,
        sendResponseToUser,
        submitRecrutmentForm,
        sendFormCourParticular,
        getNotifications,
        updateNotifications,
        getSignUpCoursParticular,
        deleteSignUpCoursParticular,
        approveTeacher,
        sendResponseToExercice,
        acceptOrRejectExerciceByTeacher,
        getTasks,
        sendLinkTeacherByEmail,
        getTaskTeacheByInterval,
        createSubscribe,
        getSubscribeById,
        saveSubscriptionExpire,
        rejectedExercice
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};
