import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { DASHBOARD_LOGIN } from "./settings/constant";

const PrivateRouteAdminStaff: React.FC<{ children: ReactNode }> = ({ children }) => {
  const tokenAdmin = localStorage.getItem("accessTokenAdminStaff");
  if (tokenAdmin) {
    return <Navigate to={DASHBOARD_LOGIN} replace />
  }
  return <>{children}</>;
};

export default PrivateRouteAdminStaff;
