import React, { ReactNode, useEffect, useState } from "react";

import "./Layout.scss";
import { BreadCrumb } from "primereact/breadcrumb";
import { MenuItem } from "primereact/menuitem";
import { Card } from "primereact/card";
import SideBar from "../components/SideBar/SideBar";
import TopBar from "../components/TopBar/TopBar";
import { COURSE_PARTICULAR, DASHBOARD_USER_HOME, HELP_EXERCICE, PROFIL_USER } from "../settings/constant";

import help_exercie from "../assets/images/help_exer_ico.webp";
import epreuve_corriger from "../assets/images/epre_corr_icon.webp";
import exman_concour from "../assets/images/exam_icon.webp";
import assistance_memoire from "../assets/images/ass_mem_icon.webp";
import stage_job from "../assets/images/jobStage.webp";
import cour_particul from "../assets/images/indual_course_icon.webp";
import solidrite from "../assets/images/solidarite_icon.webp";
import e_learning from "../assets/images/e-lean_icon.webp";
import homeImg from "../assets/images/home.svg";
import profile from "../assets/images/user-4249.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export const links = [
  { img: homeImg, label: "Tableau de bord", url: DASHBOARD_USER_HOME },
  { img: profile, label: "Mon Profil", url: PROFIL_USER },
  { img: help_exercie, label: "Aide aux exercices", url: HELP_EXERCICE },
  { img: cour_particul, label: "Cours particuliers", url: COURSE_PARTICULAR },
  { img: epreuve_corriger, label: "Recueils d'Épreuves et Corrigées", url: "" },
  { img: exman_concour, label: "Examens et Concours", url: "" },
  { img: assistance_memoire, label: "Assistance Mémoire", url: "" },
  { img: stage_job, label: "Aides aux Stages et aux Emplois", url: "" },
  { img: solidrite, label: "Solidarité", url: "" },
  { img: e_learning, label: "Formation en ligne", url: "" }, 
];

interface LayoutUserProps {
  pageName?: string;
  children: ReactNode;
}

const LayoutUser: React.FC<LayoutUserProps> = ({ children, pageName }) => {
  const location = useLocation();
  const data = location?.state;

  const items: MenuItem[] = [{ label: pageName }];
  const home: MenuItem = {
    icon: `${data ? "pi pi-arrow-left" : "pi pi-home"}`,
    url: `${data ? data.previousUrl : DASHBOARD_USER_HOME}`,
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //alert(document.referrer);
    const handleResize = () => {
      console.log(window.innerWidth < 1024);
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Vérifie initialement la taille de l'écran
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className="DashboardUserAdmin">
       <FloatingWhatsApp accountName="E-SMART" 
        phoneNumber="22955730303" 
        statusMessage="Assistance E-smart actif"
        chatMessage="Bienvenue chez E-smart ! Comment pouvons-nous vous renseigner ?" />
      <TopBar
        level="top"
        part="user"
        text="Bienvenue sur E-SMART, l'autre façon d'apprendre"
        isMobile={isMobile}
        links={links}
      />
      <TopBar level="bottom" isMobile={isMobile} part="user" links={links} isAdmin={false} />

      <div className="DashboardUserAdmin-body Dflex">
        <SideBar links={links} isMobile={isMobile} part="user" />
        <div className="DashboardUserAdmin-bodyMain">
          <BreadCrumb className="w-full mb-3" model={items} home={home} />
          <Card className="DashboardUserAdmin-container">
            {children ? children : <p>Cette page n'est pas encore disponible</p>}
          </Card>
        </div>
      </div>
    </main>
  );
};

export default LayoutUser;
