import React, { ReactNode } from "react";

import "./Layout.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className="MainApp">
      <Header />
      <div className="MainApp-pageContent">{children}</div>
      <Footer />
    </main>
  );
};

export default Layout;
