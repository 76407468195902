import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { LOGIN } from "./settings/constant";

const PrivateRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return <Navigate to={LOGIN} replace />
  }
  return <>{children}</>;
};

export default PrivateRoute;
