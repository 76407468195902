import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { API_URL, DASHBOARD_LOGIN, HOME, LOGIN, PROFIL_USER } from "../settings/constant";
import axiosInstanceUser from "../config/axiosConfig";

// Interface pour définir les types des fonctions d'authentification
interface AuthContextType {
  error: string | null;
  user: User | null;
  userProfil: any;
  admin: any;
  signUp: (formData: any) => Promise<number>;
  activeAccount: (code: string) => Promise<number>;
  signIn: (formData: FormDataSignIn) => Promise<number>;
  signOut: () => void;
  signOutAdmin: () => void;
  signOutTeacher: () => void;
  verifyEmail: (email: string) => Promise<number>;
  verifyEmailAdmin: (email: string) => Promise<number>;
  confirmEmail: (code: string, token: string) => Promise<number>;
  resetPassword: (formDate: any) => Promise<number>;
  updateUserProfil: (id: string, formData: any) => Promise<number>;
  getUserProfil: (id: string) => Promise<number>;
  signInAdmin: (formData: any) => Promise<number>;
  signInTeacher: (formData: any) => Promise<ReturnDataTeacher>;
  resetPasswordAdmin: (formData: any) => Promise<number>;
}

// Interface pour définir les types des données du formulaire
interface FormDataSignIn {
  email: string;
  password: string;
  role: string;
}

interface ReturnDataTeacher {
  status: number;
  msg: string;
  userInfo: any;
}

// Interface pour définir les données utilisateur
interface User {
  email: string;
  fullname: string;
  id: string;
  role: string;
}

interface JwtPayload {
  user: User | null;
}

// Création du contexte d'authentification
const AuthContext = createContext<AuthContextType>({
  error: null,
  user: null,
  userProfil: null,
  admin: null,
  signUp: async () => { return 500; },
  activeAccount: async () => { return 500; },
  signIn: async () => { return 500; },
  signOut: () => {},
  signOutAdmin: () => {},
  signOutTeacher: () => {},
  verifyEmail: async () => { return 500; },
  verifyEmailAdmin: async () => { return 500; },
  confirmEmail: async () => { return 500; },
  resetPassword: async () => { return 500; },
  updateUserProfil: async () => { return 500; },
  getUserProfil: async () => { return 500; },
  signInAdmin: async () => { return 500; },
  signInTeacher: async () => { return { status: 500, msg: "",  userInfo: {}}; },
  resetPasswordAdmin: async () => { return 500; },
});

// Hook personnalisé pour utiliser le contexte d'authentification dans les composants
export const useAuth = (): AuthContextType => useContext(AuthContext);

// Composant fournisseur de contexte d'authentification
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [admin, setAdmin] = useState<any>(null);
  const [userProfil, setUserProfil] = useState<any> (null);

  const signUp = async (formData: any): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}user/signup`, formData);
      console.log(response);
      return response.status;
    } catch (error: any) {
      console.error("Erreur lors de l'inscription :", error);
      setError("Une erreur est survenue lors de l'inscription.");
      return error.response?.status || 500;
    }
  };

  const activeAccount = async (code: string): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}user/active-account`, { code: code });
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de l'activation du compte :", error);
      setError("Une erreur est survenue lors de l'activation du compte.");
      return error.response?.status || 500;
    }
  };

  const signIn = async (formData: FormDataSignIn): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}user/signin`, formData);
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
          const decoded: JwtPayload = jwtDecode(data.accessToken);
          const userDetail = decoded.user;
          setUser(userDetail)

          console.log(userDetail);
          localStorage.setItem("accessToken", data.accessToken);
      }
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      return error.response?.status || 500;
    }
  };

  const verifyEmail = async (email: string): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}user/verify-email`, {
        email: email,
        origin: window.location.origin,
      });
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      setError("Une erreur est survenue lors de la connexion.");
      return error.response?.status || 500;
    }
  }

  const confirmEmail = async (code: string, token: string): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}user/confirm-email`, {
        code: code,
        token: token,
      });

      if (response.status === 200) {
        localStorage.setItem("userId", response.data.id);
      }

      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      setError("Une erreur est survenue lors de la connexion.");
      return error.response?.status || 500;
    }
  }

  const signOut = () => {
    // Logique de gestion de la déconnexion
    localStorage.removeItem("accessToken");
    window.location.href = HOME;
  };

  const signOutAdmin = () => {
    // Logique de gestion de la déconnexion
    localStorage.removeItem("accessTokenAdmin");
    window.location.href = DASHBOARD_LOGIN;
  };

  const resetPassword = async (formData: any): Promise<number> => {
    try {
      const userId = localStorage.getItem("email");
      const response = await axios.post(`${API_URL}user/reset-password`, formData);
      
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      setError("Une erreur est survenue lors de la connexion.");
      return error.response?.status || 500;
    }
  };

  const getUserProfil = async (id: string): Promise<number> => {
    try {
      const response = await axiosInstanceUser.get(`${API_URL}get-user-profil/${id}`);
      if(response?.status === 200) {
        setUserProfil(response.data);
      }
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la récupération des informations utilisateur :", error);
      return error.response?.status || 500;
    }
  }

  const updateUserProfil = async (id: string, formData: any): Promise<number> => {
    try {
      const response = await axiosInstanceUser.put(`${API_URL}user-profil-update/${id}`, formData);
      if(response?.status === 200) {
        getUserProfil(response.data.userId);
      }
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la récupération des informations utilisateur :", error);
      return error.response?.status || 500;
    }
  }

  // admin
  const signInAdmin = async (formData: any): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}admin/signin`, formData);
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
          const decoded: JwtPayload = jwtDecode(data.accessTokenAdmin);
          const userDetail = decoded.user;
          setAdmin(userDetail)

          console.log(userDetail);
          localStorage.setItem("accessTokenAdmin", data.accessTokenAdmin);
      }
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      return error.response?.status || 500;
    }
  }

  const resetPasswordAdmin = async (formData: any): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}admin/reset-pwd`, formData);      
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      return error.response?.status || 500;
    }
  };

  const verifyEmailAdmin = async (email: string): Promise<number> => {
    try {
      const response = await axios.post(`${API_URL}admin/verify-email`, {
        email: email,
        origin: window.location.origin,
      });
      return response?.status;
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      setError("Une erreur est survenue lors de la connexion.");
      return error.response?.status || 500;
    }
  }

  const signInTeacher = async (formData: any): Promise<ReturnDataTeacher> => {
    try {
      const response = await axios.post(`${API_URL}teacher/login`, formData);
      console.log(response);
      let userDetail: any =  {};
      if (response.status === 200) {
        if (response.data?.accessKeyTeacher) {
          const data = response.data;
          const decoded:any = jwtDecode(data.accessKeyTeacher);
          userDetail = decoded.user;

          console.log(userDetail);
          localStorage.setItem("accessKeyTeacher", data.accessKeyTeacher);
        }
      }
      return {status: response?.status, msg: response.data?.msg, userInfo: userDetail};
    } catch (error: any) {
      console.error("Erreur lors de la connexion :", error);
      return { status: error.response?.status || 500, msg: "error", userInfo: {}};
    }
  }

  const signOutTeacher = () => {
    localStorage.removeItem("accessKeyTeacher");
    window.location.reload();
  };


  const context: AuthContextType = {
    error,
    user,
    userProfil,
    admin,
    signUp,
    activeAccount,
    signIn,
    signOut,
    signOutAdmin,
    verifyEmail,
    confirmEmail,
    resetPassword,
    getUserProfil,
    updateUserProfil,
    signInAdmin,
    resetPasswordAdmin,
    verifyEmailAdmin,
    signInTeacher,
    signOutTeacher
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const tokenAdmin = localStorage.getItem("accessTokenAdmin");

    if (token) {
      const decoded: JwtPayload = jwtDecode(token);
      const userDetail = decoded.user;
      setUser(userDetail)

      const pathname = window.location.pathname;
      
      if(userDetail) {
        if (!pathname.includes("tableau-de-bord/enseignant"))
          getUserProfil(userDetail.id);
      }
    }

    if (tokenAdmin) {
      const decoded: JwtPayload = jwtDecode(tokenAdmin);
      const userDetail = decoded.user;
      setAdmin(userDetail)
      console.log(userDetail)
    }
  }, []);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
