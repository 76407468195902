import React, { useEffect, useState } from "react";

import "./SideBar.scss";
import { useAuth } from "../../services/AuthContext";
import infos from "../../assets/images/infos.svg";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DASHBOARD_LOGIN, DASHBOARD_USER_HOME, LOGIN, PROFIL_USER } from "../../settings/constant";
import { useNavigate } from "react-router-dom";

interface Link {
  url: string;
  img: string;
  label: string;
}

interface SideBarProps {
  links: any[];
  isMobile: boolean;
  part: string;
}

const SideBar: React.FC<SideBarProps> = ({ links, isMobile, part }) => {
  const { userProfil, admin } = useAuth();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const navigateUrl = (url: string, label: string) => {
    const token = localStorage.getItem("accessToken");
    const tokenAdmin = localStorage.getItem("accessTokenAdmin");
 
    if (label && label !== "" && label !== null) {
      sessionStorage.setItem('pathname', label);
    }

    if (url === "") {
      setVisible2(true);
      return;
    }

    if (part === "user") {
      if (token) {
        if (userProfil) {
          if (url === PROFIL_USER || url === DASHBOARD_USER_HOME) {
            window.location.href = url;
          } else {
            if (userProfil.levelStudy) {
              window.location.href = url;
            } else {
              alert("Veuillez mettre à jour votre profil pour plus d'accès.")
            }
          }
        } else {
          localStorage.removeItem("accessToken");
          window.location.href = LOGIN;
        }
      }
    } else {
      if (tokenAdmin) {
        if (admin) {
          window.location.href = url;
        } else {
          localStorage.removeItem("accessTokenAdmin");
          window.location.href = DASHBOARD_LOGIN;
        }
      }
    }
  }

  useEffect(() => {
    const allLi = document.querySelectorAll(".SideBar-li") as NodeListOf<HTMLElement>;
    const path = sessionStorage.getItem('pathname');
    
    if (path && path !== "" && path !== null && path !== undefined) {
      Array.from(allLi).forEach((li: HTMLElement) => {
        const childSpan = li.querySelector("li > span");
        
        if (childSpan && childSpan.textContent !== "" && childSpan.textContent === path) {
            li.classList.add('SideBar-active');
        }
      });
    }

    console.log(admin)
  },[admin])
  
  return (
    <div className="SideBar" style={{ display: isMobile ? "none" : "block" }}>
      <Dialog
            visible={visible2}
            modal
            style={{ width: "500px"}}
            onHide={() => setVisible2(false)}
        >
          <div className="ModalCenter">
          <div className="ModalCenter-divResult">
            <img className="mb-3" src={infos} alt="" />
            <p className="text-base text-center">Ce service n'est pas encore disponible pour le moment.</p>
          </div>
        </div>
      </Dialog>
      <Dialog
            visible={visible}
            modal
            style={{ width: "500px", height: "230px" }}
            onHide={() => setVisible(false)}
            footer={ <Button label="Ok" onClick={() => setVisible(false)} severity="danger" outlined />}
        >
          <div>
            <p className="text-base text-center">Complétez votre profil utilisateur afin d'avoir accès à toutes les fonctionnalités.</p>
          </div>
      </Dialog>
      <ul className="SideBar-ul">
        {links.map((list:any) => (
          <li className="SideBar-li"
            onClick={() => navigateUrl(list && list.url, list && list.label)} key={list && list.label}>
            <li className="SideBar-a" >
              {list && list.img && <img className="SideBar-img" src={list && list.img} alt="" />}
              <span className="SideBar-label HFont">{list && list.label}</span>
            </li>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
