export const HOME = "/";
export const LOGIN = "/connectez-vous";
export const SIGN_IN = "/inscrivez-vous";
export const ACTIVATE_ACCOUNT = "/activer-mon-compte";
export const SERVICES = "/nos-services";
export const HEAD_OFFICE = "/notre-siège";
export const POSTULE_PAGE = "/postulez-au-poste-enseignant";
export const CONTACT = "/contactez-nous";
export const CONFIRM_EMAIL = "/confirmer-mon-email/";
export const VERIFY_EMAIL = "/verifier-mon-email";
export const RESET_PWD = "/reinitialiser-mon-mot-de-passe";
export const TERMS_AND_CONDITIONS = "/conditions-d-utilisation";
export const PRIVACY_POLICY = "/politique-de-confidentialite"

export const DASHBOARD_USER_HOME = "/tableau-de-bord/";

// help exercice
export const HELP_EXERCICE = "/tableau-de-bord/aide-aux-exercices";
export const PROFIL_USER = "/tableau-de-bord/mon-profil";
export const SUBMIT_EXERCICE = "/tableau-de-bord/aide-aux-exercices/soumettre-un-exercice";
export const LIST_EXERCICE = "/tableau-de-bord/aide-aux-exercices/liste-des-exercices";
export const VERIFY_MY_SUBSCRIPTION = "/tableau-de-bord/aide-aux-exercices/verifier-mon-abonnement";
export const PROOF_AND_ANSWERS = "/tableau-de-bord/epreuves-et-corrigees";
export const EXAMS_AND_COMPETITIONS = "/tableau-de-bord/examens-et-competitions";
export const ASSISTANCE_MEMOIRE = "/tableau-de-bord/assistance-memoire";
export const PRIVATE_LESSONS = "/tableau-de-bord/cours-aux-particuliers";
export const INTENSHIPS_JOBS = "/tableau-de-bord/stages-et-emplois";
export const E_LEARNING = "/tableau-de-bord/formation-en-ligne";
export const COURSE_PARTICULAR = "/tableau-de-bord/cours-particuliers";
export const NOTIFICATIONS_USER = "/tableau-de-bord/notifications";

// admin
export const DASHBOARD_ADMIN_HOME = "/tableau-de-bord/administrateur/";
export const DASHBOARD_LOGIN = "/tableau-de-bord/administrateur/connexion";
export const HELP_EXERCICE_ADMIN = "/tableau-de-bord/administrateur/aide-aux-exercices";
export const DETAILS_EXERCICE_ADMIN = "/tableau-de-bord/administrateur/aide-aux-exercices/details";
export const CORRECTORS = "/tableau-de-bord/administrateur/enseignants";
export const ADD_CORRECTORS = "/tableau-de-bord/administrateur/nouveau";
export const TASKS = "/tableau-de-bord/administrateur/taches";
export const SERVICES_PAGE_ADMIN = "/tableau-de-bord/administrateur/services";
export const SUBSCRIPTION = "/tableau-de-bord/administrateur/subscription";
export const USERADMIN = "/tableau-de-bord/administrateur/utilisateurs"
export const RESET_PWD_ADMIN = "/tableau-de-bord/administrateur/definir-mon-mot-de-passe"
export const TASKS_ADMIN = "/tableau-de-bord/administrateur/taches";
export const MESSAGES_ADMIN = "/tableau-de-bord/administrateur/messages";
export const MESSAGES_TO_CORRECTORS = "/tableau-de-bord/administrateur/messages/enseignants";
export const MESSAGES_TO_STUDENT = "/tableau-de-bord/administrateur/messages/apprenants";
export const STAFF_DASHBOARD = "/tableau-de-bord/enseignant/:id";
export const NOTIFICATIONS_ADMIN = "/tableau-de-bord/administrateur/notifications";
export const COURSE_PARTICULAR_ADMIN = "/tableau-de-bord/administrateur/cours-particuliers";
export const VERIFY_EMAIL_ADMIN = "/tableau-de-bord/administrateur/verifier-mon-mot-de-passe";
export const SUBSCRIBE = "/tableau-de-bord/administrateur/liste-des-abonnees";

export const SPACE_TEACHER = "/tableau-de-bord/enseignant/:id"; 

//export const API_URL = "http://localhost:4002/";
export const API_URL = "https://api.e-smart.com.co/";

